export type MenuItemType = {
    key: string;
    label: string;
    isTitle?: boolean;
    icon?: string;
    url?: string;
    badge?: {
        variant: string;
        text: string;
    };
    parentKey?: string;
    target?: string;
    roles?: string[];
    children?: MenuItemType[];
};

const OPERATIONS_ROLE = 'Operations';
const ROUTING_ROLE = 'Routing';
const SALES_ROLE = 'Sales';
const MANAGER_ROLE = 'Manager';
const CUSTOMER_SERVICE_ROLE = 'CustomerService';
const ILP_ROLE = 'ILP';
const ILP_CROSSROADS_COURIER_ROLE = 'ILP-CrossRoadsCourier';
const ILP_JUST_AN_IDEA_ROLE = 'ILP-JstAnIdea';
const ROUTING_OLD_TEMP_ROLE = 'RoutingOldTemp';
const WAREHOUSE_MANAGER_ROLE = 'Warehouse-manager';

const MENU_ITEMS: MenuItemType[] = [
    {
        key: 'home',
        label: 'Home',
        isTitle: false,
        icon: 'mdi mdi-home fs-3',
        url: '/home',
        roles: [OPERATIONS_ROLE, ROUTING_ROLE, SALES_ROLE, MANAGER_ROLE, CUSTOMER_SERVICE_ROLE],
    },

    {
        key: 'routing',
        label: 'Routing',
        isTitle: false,
        icon: 'mdi mdi-directions-fork fs-3',
        roles: [MANAGER_ROLE, ROUTING_ROLE, OPERATIONS_ROLE, CUSTOMER_SERVICE_ROLE],
        children: [
            {
                key: 'routing-tasks',
                label: 'Routing tasks',
                url: '/routing/routing-tasks',
                parentKey: 'routing',
                roles: [MANAGER_ROLE, ROUTING_ROLE, OPERATIONS_ROLE, CUSTOMER_SERVICE_ROLE],
            },
            {
                key: 'better-trucks-routing-tasks',
                label: 'Routing tasks with ELS',
                url: '/better-trucks/routing-tasks',
                parentKey: 'routing',
                roles: [MANAGER_ROLE, ROUTING_ROLE, OPERATIONS_ROLE, CUSTOMER_SERVICE_ROLE],
            },
            // {
            //     key: 'routing-solutions',
            //     label: 'Routing Solutions',
            //     url: '/routing/routing-solutions',
            //     parentKey: 'routing',
            //     roles: [MANAGER_ROLE],
            // },
            // {
            //     key: 'fleet-view',
            //     label: 'Fleet View',
            //     // icon: 'uil-check-square',
            //     url: '/routing/fleet-view',
            //     parentKey: 'routing',
            // },
            // {
            //     key: 'routing-solutions-v2',
            //     label: 'Routing Solutions V2',
            //     // icon: 'uil-check-square',
            //     url: '/routing/routing-solutions-v2',
            //     parentKey: 'routing',
            //     roles: [MANAGER_ROLE],
            // },
            {
                key: '3rd-party-routing',
                label: '3rd Party Routing',
                url: '/routing/3rd-party-routing',
                parentKey: 'routing',
                roles: [MANAGER_ROLE, ROUTING_ROLE, OPERATIONS_ROLE],
            },
            {
                key: 'pickups',
                label: 'Pickups',
                url: '/routing/pickups',
                parentKey: 'routing',
                roles: [MANAGER_ROLE, ROUTING_ROLE, OPERATIONS_ROLE],
            },
            {
                key: 'pickups-sorting',
                label: 'Pickups sorting',
                url: '/routing/pickups-sorting',
                parentKey: 'routing',
                roles: [MANAGER_ROLE, ROUTING_ROLE, OPERATIONS_ROLE],
            },
        ],
    },
    {
        key: 'dashboards',
        label: 'Statistics',
        isTitle: false,
        icon: 'mdi mdi-monitor-dashboard fs-3',
        roles: [MANAGER_ROLE, ROUTING_ROLE, OPERATIONS_ROLE, CUSTOMER_SERVICE_ROLE],
        children: [
            {
                key: 'warehouse',
                label: 'Warehouse Metrics',
                url: '/dashboards/warehouse-metrics',
                parentKey: 'dashboards',
                roles: [MANAGER_ROLE, ROUTING_ROLE, OPERATIONS_ROLE, CUSTOMER_SERVICE_ROLE],
            },
        ],
    },
    {
        key: 'better-trucks',
        label: 'Better Trucks',
        isTitle: false,
        icon: 'mdi mdi-truck fs-3',
        roles: [MANAGER_ROLE, SALES_ROLE, CUSTOMER_SERVICE_ROLE],
        children: [
            {
                key: 'companies',
                label: 'Companies',
                url: '/better-trucks/companies',
                parentKey: 'better-trucks',
                roles: [MANAGER_ROLE, SALES_ROLE],
            },
            {
                key: 'cities',
                label: 'Cities',
                url: '/better-trucks/cities',
                parentKey: 'better-trucks',
                roles: [MANAGER_ROLE, CUSTOMER_SERVICE_ROLE],
            },
            {
                key: 'Ops hubs',
                label: 'BT Hubs',
                url: '/better-trucks/ops-hubs',
                parentKey: 'better-trucks',
                roles: [MANAGER_ROLE, ROUTING_ROLE, OPERATIONS_ROLE],
            },
            {
                key: 'company-hub',
                label: 'Customer Hubs',
                url: '/better-trucks/company-hubs',
                parentKey: 'better-trucks',
                roles: [MANAGER_ROLE],
            },
            {
                key: 'users',
                label: 'Users',
                url: '/better-trucks/users',
                parentKey: 'better-trucks',
                roles: [MANAGER_ROLE],
            },
            {
                key: 'zip-codes',
                label: 'Zip codes',
                url: '/better-trucks/zip-codes',
                parentKey: 'better-trucks',
                roles: [MANAGER_ROLE, SALES_ROLE],
            },
            // {
            //     key: 'zip-code-management',
            //     label: 'Zip codes exceptions',
            //     url: '/better-trucks/zip-code-exceptions',
            //     parentKey: 'better-trucks',
            // },
            {
                key: 'zip-code-management-new',
                label: 'Modify zip codes',
                url: '/better-trucks/modify-zip-code',
                parentKey: 'better-trucks',
                roles: [MANAGER_ROLE, SALES_ROLE],
            },
            {
                key: 'vehicle-templates',
                label: 'Vehicle Templates',
                url: '/better-trucks/vehicle-templates',
                parentKey: 'better-trucks',
                roles: [MANAGER_ROLE],
            },
            {
                key: 'templates',
                label: 'Templates',
                url: '/better-trucks/templates',
                parentKey: 'better-trucks',
                roles: [MANAGER_ROLE],
            },
        ],
    },
    {
        key: 'dynamic-pricing',
        label: 'Dynamic Pricing',
        isTitle: false,
        icon: 'dripicons-bookmarks fs-3',
        roles: [MANAGER_ROLE, SALES_ROLE],
        children: [
            // {
            //     key: 'all-pricing-rules',
            //     label: 'All Pricing Rules',
            //     url: '/dynamic-pricing/all-pricing-rules',
            //     parentKey: 'dynamic-pricing',
            //     roles: [MANAGER_ROLE, SALES_ROLE],
            // },
            {
                key: 'all-company-pricing-rules',
                label: 'Assigned Pricing Rules',
                url: '/dynamic-pricing/all-company-pricing-rules',
                parentKey: 'dynamic-pricing',
                roles: [MANAGER_ROLE, SALES_ROLE],
            },
            // {
            //     key: 'create-pricing-rule',
            //     label: 'Pricing Rule',
            //     url: '/dynamic-pricing/pricing-rule',
            //     parentKey: 'dynamic-pricing',
            //     roles: [MANAGER_ROLE, SALES_ROLE],
            // },
            // {
            //     key: 'active-pricing-rule',
            //     label: 'Active Pricing Rules',
            //     url: '/dynamic-pricing/active-pricing-rules',
            //     parentKey: 'dynamic-pricing',
            //     roles: [MANAGER_ROLE, SALES_ROLE],
            // },
            // {
            //     key: 'net-base-rate',
            //     label: 'Net Base Rate',
            //     url: '/dynamic-pricing/net-base-rate',
            //     parentKey: 'dynamic-pricing',
            //     roles: [MANAGER_ROLE, SALES_ROLE],
            // },
        ],
    },
    {
        key: 'orphan-labels',
        label: 'Labels',
        isTitle: false,
        icon: 'mdi mdi-database-search-outline fs-3',
        roles: [MANAGER_ROLE, ROUTING_ROLE, OPERATIONS_ROLE, CUSTOMER_SERVICE_ROLE],
        children: [
            // {
            //     key: 'orphan-labels-manifest',
            //     label: 'Add single package',
            //     url: '/add-package',
            //     parentKey: 'orphan-labels',
            //     roles: [MANAGER_ROLE, ROUTING_ROLE, OPERATIONS_ROLE, CUSTOMER_SERVICE_ROLE],
            // },
            // {
            //     key: 'orphan-labels-api-customer',
            //     label: 'Verify labels',
            //     url: '/verify-label',
            //     parentKey: 'orphan-labels',
            //     roles: [MANAGER_ROLE, ROUTING_ROLE, OPERATIONS_ROLE, CUSTOMER_SERVICE_ROLE],
            // },
            {
                key: 'orphans-labels-api-customer',
                label: 'Verify label / Add package',
                url: '/verify-label-add-package',
                parentKey: 'orphan-labels',
                roles: [MANAGER_ROLE, ROUTING_ROLE, OPERATIONS_ROLE, CUSTOMER_SERVICE_ROLE],
            },
        ],
    },
    {
        key: 'container-labels',
        label: 'Containers',
        isTitle: false,
        icon: 'dripicons-article fs-3',
        roles: [MANAGER_ROLE, ROUTING_ROLE, OPERATIONS_ROLE],
        children: [
            // {
            //     key: 'track container',
            //     label: 'Track containers',
            //     url: '/track-containers',
            //     parentKey: 'contaier-labels',
            //     roles: [MANAGER_ROLE, ROUTING_ROLE, OPERATIONS_ROLE],
            // },
            {
                key: 'metro-containers',
                label: 'Metro containers',
                url: '/container-label',
                parentKey: 'container-labels',
                roles: [MANAGER_ROLE, ROUTING_ROLE, OPERATIONS_ROLE],
            },
            {
                key: 'Company Containers',
                label: 'Company containers',
                url: '/company-container-label',
                parentKey: 'container-labels',
                roles: [MANAGER_ROLE, ROUTING_ROLE, OPERATIONS_ROLE],
            },
            {
                key: 'generic-containers',
                label: 'Generic containers',
                url: '/generic-container-label',
                parentKey: 'container-labels',
                roles: [MANAGER_ROLE, ROUTING_ROLE, OPERATIONS_ROLE],
            },
            {
                key: 'multi-metro-config',
                label: 'Multi-metro config',
                url: '/container-hub-config',
                parentKey: 'container-labels',
                roles: [MANAGER_ROLE, ROUTING_ROLE, OPERATIONS_ROLE],
            },
            {
                key: 'multi-company-config',
                label: 'Multi company config',
                url: '/multi-company-config',
                parentKey: 'container-labels',
                roles: [MANAGER_ROLE, ROUTING_ROLE, OPERATIONS_ROLE],
            },
            {
                key: 'Line haul config',
                label: 'Line haul config',
                url: '/line-haul-config',
                parentKey: 'container-labels',
                roles: [MANAGER_ROLE, ROUTING_ROLE, OPERATIONS_ROLE],
            },
            {
                key: 'Zone SubZone',
                label: 'Zone ZubZone',
                url: '/zone-subZone',
                parentKey: 'container-labels',
                roles: [MANAGER_ROLE, ROUTING_ROLE, OPERATIONS_ROLE],
            },
            {
                key: 'Route',
                label: 'Route',
                url: '/route',
                parentKey: 'container-labels',
                roles: [MANAGER_ROLE, ROUTING_ROLE, OPERATIONS_ROLE],
            },
            {
                key: 'uber-trailers',
                label: 'Uber trailers',
                url: '/uber-trailers',
                parentKey: 'container-labels',
                roles: [MANAGER_ROLE, ROUTING_ROLE, OPERATIONS_ROLE],
            },
            {
                key: 'uber-routes',
                label: 'Uber routes',
                url: '/uber-routes',
                parentKey: 'container-labels',
                roles: [WAREHOUSE_MANAGER_ROLE],
            },
        ],
    },
    // {
    //     key: 'apps-file-manager',
    //     label: 'File Manager',
    //     isTitle: false,
    //     icon: 'uil-folder-plus',
    //     url: '/apps/file',
    // },

    // { key: 'custom', label: 'Custom', isTitle: true },
    {
        key: 'customer-service',
        label: 'Customer services',
        isTitle: false,
        icon: 'uil-copy-alt fs-3',
        roles: [MANAGER_ROLE, ROUTING_ROLE, OPERATIONS_ROLE, SALES_ROLE, CUSTOMER_SERVICE_ROLE],
        children: [
            // {
            //     key: 'page-profile',
            //     label: 'Track shipment',
            //     url: '/customer-service/track-shipment',
            //     parentKey: 'customer-service',
            // },
            {
                key: 'podReview',
                label: 'POD Review',
                url: '/customer-service/pod-review',
                parentKey: 'customer-service',
                roles: [MANAGER_ROLE, OPERATIONS_ROLE],
            },
            {
                key: 'track-shipments',
                label: 'Track shipments',
                url: '/customer-service/track-shipments',
                parentKey: 'customer-service',
                roles: [MANAGER_ROLE, ROUTING_ROLE, OPERATIONS_ROLE, SALES_ROLE, CUSTOMER_SERVICE_ROLE],
            },
            {
                key: 'track-containers',
                label: 'Track Containers',
                url: '/labels',
                parentKey: 'customer-service',
                roles: [MANAGER_ROLE, ROUTING_ROLE, OPERATIONS_ROLE],
            },
            {
                key: 'claims',
                label: 'Claims',
                url: '/customer-service/claims',
                parentKey: 'customer-service',
                roles: [MANAGER_ROLE, SALES_ROLE, CUSTOMER_SERVICE_ROLE],
            },
            // {
            //     key: 'page-company-service-levels',
            //     label: 'Service level exceptions',
            //     url: '/customer-service/service-levels-exceptions',
            //     parentKey: 'customer-service',
            //     roles: [MANAGER_ROLE, SALES_ROLE],
            // },
            {
                key: 'page-service-levels',
                label: 'Service levels',
                url: '/customer-service/service-levels',
                parentKey: 'customer-service',
                roles: [MANAGER_ROLE, SALES_ROLE],
            },
            {
                key: 'event-types',
                label: 'Event types',
                url: '/customer-service/event-types',
                parentKey: 'customer-service',
                roles: [MANAGER_ROLE],
            },
            {
                key: 'address-corrections-notifications',
                label: 'Address Corrections Notifications',
                url: '/customer-service/address-corrections-notifications',
                parentKey: 'customer-service',
                roles: [MANAGER_ROLE],
            },
            {
                key: 'address-corrections-surcharge',
                label: 'Address Correction Surcharge',
                url: '/customer-service/address-corrections-surcharge',
                parentKey: 'customer-service',
                roles: [MANAGER_ROLE],
            },
        ],
    },
    {
        key: 'fleet-ops',
        label: 'Fleet Ops',
        isTitle: false,
        icon: 'dripicons-web fs-3',
        roles: [MANAGER_ROLE, ROUTING_ROLE, OPERATIONS_ROLE, SALES_ROLE, CUSTOMER_SERVICE_ROLE, ILP_ROLE],
        children: [
            {
                key: 'fleet-view',
                label: 'Fleet View',
                // icon: 'uil-check-square',
                url: '/fleet-ops/fleet-view',
                parentKey: 'fleet-ops',
                roles: [MANAGER_ROLE, ROUTING_ROLE, OPERATIONS_ROLE, SALES_ROLE, CUSTOMER_SERVICE_ROLE, ILP_ROLE],
            },
            {
                key: 'zip-based-routing',
                label: 'Zip based routing',
                url: '/fleet-ops/zip-based-routing',
                parentKey: 'fleet-ops',
                roles: [
                    MANAGER_ROLE,
                    ROUTING_ROLE,
                    OPERATIONS_ROLE,
                    SALES_ROLE,
                    CUSTOMER_SERVICE_ROLE,
                    ILP_CROSSROADS_COURIER_ROLE,
                ],
            },
            {
                key: 'driver-assignment',
                label: 'Driver assignment',
                url: '/fleet-ops/driver-assignment',
                parentKey: 'fleet-ops',
                roles: [
                    MANAGER_ROLE,
                    ROUTING_ROLE,
                    OPERATIONS_ROLE,
                    SALES_ROLE,
                    CUSTOMER_SERVICE_ROLE,
                    ILP_CROSSROADS_COURIER_ROLE,
                ],
            },
            {
                key: 'driver-management',
                label: 'Driver management',
                url: '/fleet-ops/driver-management',
                parentKey: 'fleet-ops',
                roles: [MANAGER_ROLE, ROUTING_ROLE, OPERATIONS_ROLE, SALES_ROLE, CUSTOMER_SERVICE_ROLE, ILP_ROLE],
            },
            {
                key: 'package-inquiry',
                label: 'Package inquiry',
                url: '/fleet-ops/package-inquiry',
                parentKey: 'fleet-ops',
                roles: [
                    MANAGER_ROLE,
                    ROUTING_ROLE,
                    OPERATIONS_ROLE,
                    SALES_ROLE,
                    CUSTOMER_SERVICE_ROLE,
                    ILP_CROSSROADS_COURIER_ROLE,
                ],
            },
        ],
    },
    {
        key: 'third-party-carriers',
        label: '3rd Party Carriers',
        isTitle: false,
        icon: 'dripicons-direction fs-3',
        roles: [MANAGER_ROLE, ROUTING_ROLE, OPERATIONS_ROLE, SALES_ROLE, CUSTOMER_SERVICE_ROLE],
        children: [
            {
                key: 'carriers',
                label: 'Carriers',
                url: '/third-party-carriers/carriers',
                parentKey: 'third-party-carriers',
                roles: [MANAGER_ROLE, ROUTING_ROLE, OPERATIONS_ROLE, SALES_ROLE, CUSTOMER_SERVICE_ROLE],
            },
            {
                key: 'carrier-zips',
                label: 'Zips',
                url: '/third-party-carriers/carrier-zips',
                parentKey: 'third-party-carriers',
                roles: [MANAGER_ROLE, ROUTING_ROLE, OPERATIONS_ROLE, SALES_ROLE, CUSTOMER_SERVICE_ROLE],
            },
            {
                key: 'partner-monitoring',
                label: 'Partner Monitoring',
                url: '/third-party-carriers/partner-monitoring',
                parentKey: 'third-party-carriers',
                roles: [MANAGER_ROLE, ROUTING_ROLE, OPERATIONS_ROLE, SALES_ROLE, CUSTOMER_SERVICE_ROLE],
            },
        ],
    },
];

export {
    MENU_ITEMS,
    OPERATIONS_ROLE,
    ROUTING_ROLE,
    SALES_ROLE,
    MANAGER_ROLE,
    CUSTOMER_SERVICE_ROLE,
    ROUTING_OLD_TEMP_ROLE,
    ILP_ROLE,
    ILP_CROSSROADS_COURIER_ROLE,
    ILP_JUST_AN_IDEA_ROLE,
    WAREHOUSE_MANAGER_ROLE,
};
